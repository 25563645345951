import React, { useRef, useState } from "react";

import eyeShow from "./../../../assets/eye-show.svg";
import eyeHide from "./../../../assets/eye-hide.svg";
import useRunAfterUpdate from "../../../hooks/useRunAfterUpdate";

interface PasswordInputProps {
  className: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

const PasswordInput = React.forwardRef<HTMLDivElement, PasswordInputProps>(
  (props, ref) => {
    const [seeType, setSeeType] = useState("password");
    const runAfterUpdate = useRunAfterUpdate();
    const _ref = useRef<HTMLInputElement>(null);

    const toggleType = () => {
      if (seeType === "password") {
        setSeeType("text");
        const input = _ref.current;

        runAfterUpdate(() => {
          if (input) {
            input.focus();
            input.selectionStart = input.value.length;
            input.selectionEnd = input.value.length;
          }
        });
      } else {
        setSeeType("password");
        const input = _ref.current;

        runAfterUpdate(() => {
          if (input) {
            input.focus();
            input.selectionStart = input.value.length;
            input.selectionEnd = input.value.length;
          }
        });
      }
    };

    const allowedAttr = [
      "id",
      "value",
      "onChange",
      "placeholder",
      "className",
      "maxLength",
      "disabled",
      "tabIndex",
    ];

    const propsAttr = Object.keys(props)
      .filter((key) => allowedAttr.includes(key))
      .reduce((obj: any, key) => {
        const _props: any = props;
        obj[key] = _props[key];
        return obj;
      }, {});

    return (
      <div className="relative h-content password-input" ref={ref}>
        <input {...propsAttr} ref={_ref} type={seeType} />
        {seeType === "password" ? (
          <span onClick={toggleType} className="eye show">
            <img src={eyeShow} alt="" />
          </span>
        ) : (
          <span onClick={toggleType} className="eye hide">
            <img src={eyeHide} alt="" />
          </span>
        )}
      </div>
    );
  }
);

export default PasswordInput;
