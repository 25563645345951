import React from "react";

import bg from "./../../../assets/background.svg";

interface BackgroundProps {}

const Background: React.FC<BackgroundProps> = () => {
  return (
    <figure
      className="w-2/3 portrait:w-full bg-no-repeat bg-bottom bg-center bg-cover"
      style={{ backgroundImage: `url(${bg})` }}
    ></figure>
  );
};

export default Background;
