import React, { useEffect, useState } from "react";

import ProfileContext from "./ProfileContext";
import { useAuthenticatedApi } from "../../hooks/useApi";
import { GeneralObject } from "../../definitions/general";

interface ProfileProviderProps {}

const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
  const [profile, setProfile] = useState({});
  const api = useAuthenticatedApi("PROFILE_ME");

  useEffect(() => {
    if (api) {
      api
        .get("")
        .then((e) => {
          console.log("Profile", e);
          setProfile(e.data.profile);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [api]);

  const updateProfile = (updatedProfile: GeneralObject) => {
    setProfile((_profile) => Object.assign(_profile, updateProfile));
  };

  return (
    <ProfileContext.Provider value={{ profile, updateProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
