import { useLayoutEffect, useRef } from "react";

type func = () => void;

const useRunAfterUpdate = () => {
  const afterPaintRef = useRef<func | null>(null);

  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });

  const runAfterUpdate = (fn: any) => (afterPaintRef.current = fn);
  return runAfterUpdate;
};

export default useRunAfterUpdate;
