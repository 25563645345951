import { parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { GeneralObject } from "../../../definitions/general";
import { getCustomApi, useUnauthenticatedApi } from "../../../hooks/useApi";
import useInput from "../../../hooks/useInput";
import useResponseFlash from "../../../hooks/useResponseFlash";
import MobileInput from "../../ui/MobileInput";
import OTPInput from "../../ui/OTPInput";
import PasswordInput from "../../ui/PasswordInput";

interface FormsProps {
  isReset: boolean;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
}

const Forms: React.FC<FormsProps> = ({ isReset, setReset }) => {
  const loginApi = useUnauthenticatedApi("LOGIN");
  const activateApi = useUnauthenticatedApi("ACTIVATE_ACCOUNT");
  const accountCheckApi = useUnauthenticatedApi("ACCOUNT_CHECK");
  const requestOTPApi = useUnauthenticatedApi("REQUEST_OTP");
  const { setTokenDetails, setUser, source, setAllowSSO, clientId } =
    useContext(AuthContext);

  const { value: number, bind: bindNumber, setValue: setNumber } = useInput("");
  const { value: passwordLogin, bind: bindPasswordLogin } = useInput("");
  const { value: firstName, bind: bindFirstName } = useInput("");
  const { value: middleName, bind: bindMiddleName } = useInput("");
  const { value: lastName, bind: bindLastName } = useInput("");
  const { value: passwordSignup, bind: bindPasswordSignup } = useInput("");
  const { value: passwordSignupRe, bind: bindPasswordSignupRe } = useInput("");
  const { response, setResponse } = useResponseFlash();
  const { response: resentOtp, setResponse: setResentOTP } = useResponseFlash();

  const [loading, setLoading] = useState<boolean>(false);
  const [isValidNumber, setValidNumber] = useState<boolean>(false);
  const [isValidLogin, setIsValidLogin] = useState<boolean>(false);
  const [isValidOTP, setIsValidOTP] = useState<boolean>(false);
  const [isValidSignup, setIsValidSignup] = useState<boolean>(false);
  const [isValidReset, setIsValidReset] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>();
  const [tempDetails, setTempDetails] = useState<GeneralObject>({});
  const [flow, setFlow] = useState<string | null>(null);

  const mobileRef = useRef<HTMLDivElement>(null);
  const otpRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLDivElement>(null);
  const resetPasswordRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  const isButtonValid = useMemo(() => {
    if (flow == null) {
      return isValidNumber;
    } else if (flow === "login") {
      return isValidNumber && isValidLogin;
    } else if (flow === "otp") {
      return isValidNumber && isValidOTP;
    } else if (flow === "signup") {
      return isValidSignup;
    } else if (flow === "reset") {
      return isValidReset;
    }
  }, [
    flow,
    isValidLogin,
    isValidNumber,
    isValidOTP,
    isValidReset,
    isValidSignup,
  ]);

  const formattedNumber = useMemo(
    () =>
      parsePhoneNumberFromString(`+${number}`)
        ? parsePhoneNumberFromString(`+${number}`)
        : parsePhoneNumberFromString(`${number}`),
    [number]
  );

  useEffect(() => {
    if (flow === "login") {
      if (!formattedNumber || !formattedNumber.isValid()) {
        if (mobileRef.current) {
          const el = mobileRef.current.querySelector("input");
          if (el) {
            el.focus();
          }
        }
      } else {
        if (passwordRef.current) {
          const el = passwordRef.current.querySelector("input");
          if (el) {
            el.focus();
          }
        }
      }
    } else if (flow === "otp") {
      if (otpRef.current) {
        otpRef.current.focus();
      }
    } else if (flow === "signup") {
      if (nameRef.current) {
        nameRef.current.focus();
      }
    } else if (flow === "reset") {
      if (resetPasswordRef.current) {
        const el = resetPasswordRef.current.querySelector("input");
        if (el) {
          el.focus();
        }
      }
    } else {
      if (mobileRef.current) {
        const el = mobileRef.current.querySelector("input");
        if (el) {
          el.focus();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow]);

  useEffect(() => {
    if (!formattedNumber || !formattedNumber.isValid()) {
      setValidNumber(false);
    } else {
      setValidNumber(true);
    }
  }, [formattedNumber]);

  useEffect(() => {
    if (flow === "login") {
      if (passwordLogin && passwordLogin.length >= 8) {
        setIsValidLogin(true);
      } else {
        setIsValidLogin(false);
      }
    }
  }, [flow, passwordLogin]);

  useEffect(() => {
    if (otp != null && otp.length === 6) {
      setIsValidOTP(true);
    } else {
      setIsValidOTP(false);
    }
  }, [otp]);

  useEffect(() => {
    if (
      firstName &&
      firstName.trim().length &&
      passwordSignup &&
      passwordSignupRe &&
      passwordSignup === passwordSignupRe
    ) {
      setIsValidSignup(true);
    } else {
      setIsValidSignup(false);
    }
  }, [firstName, passwordSignup, passwordSignupRe]);

  useEffect(() => {
    if (
      passwordSignup &&
      passwordSignupRe &&
      passwordSignup === passwordSignupRe
    ) {
      setIsValidReset(true);
    } else {
      setIsValidReset(false);
    }
  }, [firstName, passwordSignup, passwordSignupRe]);

  const sendOTP = async () => {
    setLoading(true);
    if (formattedNumber) {
      const data = {
        mobile_country_code: formattedNumber.countryCallingCode,
        mobile: formattedNumber.nationalNumber,
      };
      try {
        const response = await requestOTPApi.post("", JSON.stringify(data));
        console.log(response);
        if (response.status === 200) {
          setFlow("otp");
        } else {
          setResponse("Error while sending OTP.");
        }
      } catch (error) {
        setResponse("Error while sending the OTP.");
        console.log(error);
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMobileCheck = async () => {
    if (formattedNumber) {
      setLoading(true);
      try {
        const res = await accountCheckApi.get(
          `/?mobile=${formattedNumber.nationalNumber}&mobile_country_code=${formattedNumber.countryCallingCode}`
        );
        console.log(res);
        if (res.status === 200) {
          if (isReset) {
            sendOTP();
          } else {
            if (res.data.exists) {
              setFlow("login");
            } else {
              sendOTP();
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleLogin = async () => {
    if (formattedNumber) {
      setLoading(true);

      const data = JSON.stringify({
        mobile_country_code: formattedNumber.countryCallingCode,
        mobile: formattedNumber.nationalNumber,
        password: passwordLogin,
      });

      console.log(data);
      try {
        const res = await loginApi.post("", data);
        console.log(res);
        if (res.status === 200) {
          setAllowSSO(true);
          setTokenDetails(res.data.user_token, res.data.token_expiry);
          setUser(res.data.user);
        } else {
          setResponse("Incorrect credentials");
        }
      } catch (error) {
        setResponse("Error in logging you in.");
        console.log(error);
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleVerification = async () => {
    if (formattedNumber) {
      setLoading(true);
      const data = {
        mobile_country_code: formattedNumber.countryCallingCode,
        mobile: formattedNumber.nationalNumber,
        otp,
      };
      try {
        const res = await activateApi.post("", JSON.stringify(data));
        console.log(res);
        if (res.status === 200) {
          setTokenDetails(res.data.user_token, res.data.token_expiry);
          setTempDetails(res.data);
          if (isReset) {
            setFlow("reset");
          } else {
            setFlow("signup");
          }
        } else {
          setResponse("Invalid OTP");
        }
      } catch (error) {
        setResponse("Error in validating. Try again later.");
        console.log(error);
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    }
  };

  const setName = async (next: any) => {
    const profileApi = getCustomApi(
      "PROFILE_ME",
      tempDetails.user_token,
      clientId,
      tempDetails.user.user_id
    );
    if (profileApi) {
      const data: GeneralObject = {
        first_name: firstName.trim(),
      };
      if (middleName.trim().length) {
        data.middle_name = middleName.trim();
      }
      if (lastName.trim().length) {
        data.last_name = lastName.trim();
      }
      try {
        const response = await profileApi.post("", JSON.stringify(data));
        console.log(response);
        if (response.status === 200) {
          if (next) {
            next();
          }
        } else {
          setResponse("Error in setting name.");
          if (next) {
            next();
          }
        }
      } catch (error) {
        setResponse("Error in setting name.");
        if (next) {
          next();
        }
        console.log(error);
        console.log(error.response);
      }
    }
  };

  const setPassword = async () => {
    const passwordApi = getCustomApi(
      "SET_PASSWORD",
      tempDetails.user_token,
      clientId,
      tempDetails.user.user_id
    );
    if (passwordApi) {
      setLoading(true);
      const data = {
        password: passwordSignupRe,
      };
      try {
        const response = await passwordApi.post("", JSON.stringify(data));
        console.log(response);
        if (response.status === 200) {
          if (isReset) {
            setUser({ ...tempDetails.user });
            setAllowSSO(true);
            setReset(false);
          } else {
            setUser({ ...tempDetails.user, signup: true });
          }
        } else {
          setResponse("Error in setting password.");
        }
      } catch (error) {
        setResponse("Error in setting password.");
        console.log(error);
        console.log(error.response);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSignup = () => {
    setLoading(true);
    setName(setPassword);
  };

  const handleReset = () => {
    setFlow(null);
    setReset(true);
  };

  const handleResendOTP = () => {
    setResentOTP(true);
    sendOTP();
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      if (flow == null) {
        handleMobileCheck();
      } else if (flow === "login") {
        handleLogin();
      } else if (flow === "otp") {
        handleVerification();
      } else if (flow === "signup") {
        handleSignup();
      } else if (flow === "reset") {
        setPassword();
      }
    }
  };

  return (
    <aside className="w-1/3 portrait:w-full portrait:absolute top-0 left-0 portrait:p-12 shadow-inner flex flex-col items-center">
      <header
        className={`px-4 bg-white flex-col flex items-center w-full flex-shrink-0 h-content portrait:rounded-t-lg ${
          flow != null ? (flow === "signup" ? "py-8" : "py-12") : "py-24"
        }`}
      >
        {source === "zostel" ? (
          <>
            <h2
              className={`font-bold text-3xl text-gray-700 ${
                flow != null ? "mb-0" : "mb-1"
              }`}
            >
              Log in to
            </h2>
            <h1 className="font-black text-5xl text-orange">Zostel</h1>
          </>
        ) : (
          <>
            <h2
              className={`font-bold text-3xl text-gray-700 ${
                flow != null ? "mb-0" : "mb-1"
              }`}
            >
              Check in to
            </h2>
            <h1 className="font-black text-5xl text-orange">Zo World</h1>
          </>
        )}
      </header>
      <section className="px-4 py-6 w-full flex-grow bg-gray-100 portrait:rounded-b-lg">
        <form
          className="flex flex-col items-center w-full"
          onSubmit={handleFormSubmit}
        >
          <span
            className={`text-xl font-medium ${
              flow === "signup" || flow === "reset" ? "mb-4" : "mb-2"
            }`}
          >
            {flow === "login"
              ? "Welcome Back!"
              : flow === "otp"
              ? "Verify Yourself!"
              : flow === "reset"
              ? "Enter your new password"
              : flow === "signup"
              ? `Welcome${firstName ? `, ${firstName}` : ""}!`
              : "Enter your mobile number"}
          </span>
          {flow !== "signup" && flow !== "reset" && (
            <MobileInput
              value={bindNumber.value}
              setter={setNumber}
              ref={mobileRef}
            />
          )}
          <div className="h-content">
            {flow === "login" ? (
              <PasswordInput
                placeholder="Password"
                {...bindPasswordLogin}
                className="form-input w-84 p-4"
                ref={passwordRef}
              />
            ) : flow === "otp" ? (
              <div className="flex flex-col items-center">
                <span className="text-sm mb-4">
                  Enter the OTP that we just sent you on Whatsapp.
                </span>
                <OTPInput onChange={(e: any) => setOtp(e)} ref={otpRef} />
              </div>
            ) : flow === "signup" ? (
              <div className="flex flex-col items-center">
                <input
                  className="form-input w-84 p-4 mb-1"
                  type="text"
                  placeholder="First Name (required)"
                  {...bindFirstName}
                  ref={nameRef}
                />
                <input
                  className="form-input w-84 p-4 mb-1"
                  type="text"
                  placeholder="Middle Name"
                  {...bindMiddleName}
                />
                <input
                  className="form-input w-84 p-4"
                  type="text"
                  placeholder="Last Name"
                  {...bindLastName}
                />
                <div className="p-4" />
                <PasswordInput
                  placeholder="Password (required)"
                  {...bindPasswordSignup}
                  className="form-input w-84 p-4 mb-1"
                />
                <PasswordInput
                  placeholder="Confirm Password (required)"
                  {...bindPasswordSignupRe}
                  className="form-input w-84 p-4"
                />
              </div>
            ) : flow === "reset" ? (
              <div className="flex flex-col items-center">
                <PasswordInput
                  placeholder="Password (required)"
                  {...bindPasswordSignup}
                  className="form-input w-84 p-4 mb-1"
                  ref={resetPasswordRef}
                />
                <PasswordInput
                  placeholder="Confirm Password (required)"
                  {...bindPasswordSignupRe}
                  className="form-input w-84 p-4"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="h-6 flex items-center justify-center font-semibold text-xs text-orange">
            {response}
          </div>
          {flow === "login" ? (
            <div className="text-xs text-gray-700 mb-2">
              Forgot Password?{" "}
              <button
                className="text-orange focus:outline-none font-medium hover:underline"
                type="button"
                onClick={handleReset}
              >
                Click here to reset
              </button>
            </div>
          ) : flow === "otp" ? (
            resentOtp ? (
              <div className="text-xs text-orange font-medium mb-2">
                Re-sent OTP!
              </div>
            ) : (
              <div className="text-xs text-gray-700 mb-2">
                Didn't receive the OTP?{" "}
                <button
                  className="text-orange focus:outline-none font-medium hover:underline"
                  type="button"
                  onClick={handleResendOTP}
                >
                  Click here to resend
                </button>
              </div>
            )
          ) : null}
          <button
            type="submit"
            className={`focus:outline-none font-medium px-4 py-3 text-lg rounded-lg shadow-sm border ${
              isButtonValid
                ? `bg-orange text-white hover:shadow-md border-orange`
                : "bg-gray-200 text-gray-700 cursor-default"
            }`}
            disabled={loading}
          >
            {flow != null
              ? flow === "otp"
                ? "Verify"
                : "Check In"
              : "Proceed"}
          </button>
        </form>
      </section>
    </aside>
  );
};

export default Forms;
