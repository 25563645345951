import React, { useContext, useState } from "react";
import { Route, Switch } from "react-router-dom";
import AuthoriseSection from "./components/sections/Authorise";
import Forms from "./components/sections/Forms";
import NotAllowed from "./components/sections/NotAllowed";
import SSOError from "./components/sections/SSOError";
import UserSection from "./components/sections/User";
import ProtectedRoute from "./components/structure/ProtectedRoute";

import Background from "./components/ui/Background";
import { AuthContext } from "./contexts/auth";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const { isLoggedIn } = useContext(AuthContext);

  const [isReset, setReset] = useState<boolean>(false);

  return (
    <main className="flex items-stretch w-full h-full">
      <Background />
      <Switch>
        <ProtectedRoute path="/not-allowed" component={NotAllowed} />
        <ProtectedRoute path="/authorise" component={AuthoriseSection} />
        <ProtectedRoute path="/sso-error" component={SSOError} />

        <Route path="/">
          {isLoggedIn ? (
            isReset ? (
              <Forms isReset={isReset} setReset={setReset} />
            ) : (
              <UserSection reset={setReset.bind(null, true)} />
            )
          ) : (
            <Forms isReset={isReset} setReset={setReset} />
          )}
        </Route>
      </Switch>
    </main>
  );
};

export default App;
