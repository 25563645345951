import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router";
import APPS from "../../../config/apps";
import { AuthContext } from "../../../contexts/auth";

interface NotAllowedProps {}

const NotAllowed: React.FC<NotAllowedProps> = () => {
  const { source, ssoDetails } = useContext(AuthContext);
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const clientName = useMemo(() => {
    if (ssoDetails) {
      if (APPS[ssoDetails.app_id]) {
        return APPS[ssoDetails.app_id];
      }
    }
    return "an app";
  }, [ssoDetails]);

  return (
    <aside className="w-1/3 portrait:w-full portrait:absolute top-0 left-0 portrait:p-12 shadow-inner flex flex-col items-center">
      <header className="px-4 bg-white flex-col flex items-center w-full flex-shrink-0 h-content py-12 portrait:rounded-t-lg">
        <h1 className="font-bold text-5xl text-orange">
          {source === "zostel" ? "Zostel" : "Zo World"}
        </h1>
        <h2 className="font-bold text-3xl text-gray-700">Uh oh!</h2>
      </header>
      <section className="flex flex-col items-center w-full bg-gray-100 portrait:rounded-b-lg py-12 px-4">
        <span className="text-gray-800 text-xl text-center">
          You are not authorised to use this App.
          <br />
          <a
            className="text-orange font-medium"
            href={`https://api.whatsapp.com/send?phone=919599407770&text=${encodeURI(
              `Hi Zostel, I need help in accessing ${clientName}!`
            )}&source=&data=&app_absent=`}
          >
            Contact the team
          </a>{" "}
          for any help.
        </span>
        <button
          className="bg-orange font-medium px-4 py-3 text-white rounded-lg shadow-md mt-8"
          onClick={goBack}
        >
          Go Back
        </button>
      </section>
    </aside>
  );
};

export default NotAllowed;
