import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import APPS from "../../../config/apps";
import { AuthContext } from "../../../contexts/auth";
import { ProfileContext } from "../../../contexts/profile";
import Avatar from "../../ui/Avatar";

interface AuthoriseSectionProps {}

const AuthoriseSection: React.FC<AuthoriseSectionProps> = () => {
  const { source, setAllowSSO, logout, ssoDetails } = useContext(AuthContext);
  const history = useHistory();
  const { profile } = useContext(ProfileContext);

  const [autoLoginCheckbox, setAutoLogin] = useState<boolean>(true);

  useEffect(() => {
    if (ssoDetails) {
      const isAutoLogin = localStorage.getItem(`auto - ${ssoDetails.app_id}`);
      if (isAutoLogin && isAutoLogin === "true") {
        setAllowSSO(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoDetails]);

  const allow = () => {
    if (ssoDetails) {
      if (autoLoginCheckbox) {
        localStorage.setItem(`auto - ${ssoDetails.app_id}`, "true");
      } else {
        localStorage.removeItem(`auto - ${ssoDetails.app_id}`);
      }
    }
    setAllowSSO(true);
  };

  const reject = () => {
    history.push("/");
    logout();
  };

  const clientName = useMemo(() => {
    if (ssoDetails) {
      if (APPS[ssoDetails.app_id]) {
        return APPS[ssoDetails.app_id];
      }
    }
    return "An App";
  }, [ssoDetails]);

  const handleAutoLogin: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setAutoLogin(e.target.checked);
  };

  return (
    <aside className="w-1/3 portrait:w-full portrait:absolute top-0 left-0 portrait:p-12 shadow-inner flex flex-col items-center">
      <header className="px-4 bg-white flex-col flex items-center w-full flex-shrink-0 h-content py-12 portrait:rounded-t-lg">
        <h1 className="font-bold text-5xl text-orange">
          {source === "zostel" ? "Zostel" : "Zo World"}
        </h1>
        <h2 className="font-semibold text-3xl text-gray-700">
          Authorise Access
        </h2>
      </header>
      <section className="flex flex-col items-center w-full bg-gray-100 portrait:rounded-b-lg py-12 px-8">
        <span className="text-gray-800 text-xl text-center mb-8">
          <strong>{clientName}</strong> is requesting access to the following
          account.
        </span>
        {profile && (
          <>
            <Avatar
              className="w-24 h-24 bg-white border rounded-full"
              svg={profile.avatar_url}
            />
            <div className="text-gray-800 font-medium text-lg mt-2">
              {profile.nickname || profile.first_name || "Zosteler"}
            </div>
          </>
        )}
        <label className="mt-6 text-gray-700 flex items-center text-sm">
          <input
            type="checkbox"
            className="mr-2"
            checked={autoLoginCheckbox}
            onChange={handleAutoLogin}
          />
          Auto login next time
        </label>
        <button
          className="bg-orange font-medium px-4 py-3 text-white rounded-lg shadow-md mt-2"
          onClick={allow}
        >
          Allow
        </button>
        <button className="mt-2 text-gray-600 font-medium" onClick={reject}>
          Use different account
        </button>
      </section>
    </aside>
  );
};

export default AuthoriseSection;
