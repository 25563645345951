import { useState } from "react";

const useInput: (
  initialValue: string
) => {
  value: string;
  setValue: React.Dispatch<any>;
  reset: () => void;
  bind: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
} = (initialValue) => {
  const [value, setValue] = useState<string>(initialValue);

  const bind = {
    value,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
  };

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind,
  };
};

export default useInput;
