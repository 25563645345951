export type ProfileEndpoint =
  | "PROFILE_ME"
  | "PROFILE_SEED"
  | "LOCATION"
  | "PROFILE_ASSETS"
  | "COUNTRY"
  | "STATE"
  | "CITY";

export const PROFILE_ENDPOINTS: { [key: string]: string } = {
  PROFILE_ME: "/me/",
  PROFILE_ASSETS: "/me/assets/",
  PROFILE_SEED: "/seed/",
  LOCATION: "/locations/",
  COUNTRY: "/locations/country/",
  STATE: "/locations/state/",
  CITY: "/locations/city/",
};
