import axios, { AxiosInstance } from "axios";
import { useContext, useMemo } from "react";
import { AuthContext } from "../contexts/auth";
import { Endpoint, getApiEndpoint } from "../utils/api";

type ApiType = "unauthenticated" | "anonymous";

const useUnauthenticatedApi: (
  endpoint: Endpoint,
  route?: string
) => AxiosInstance = (endpoint, route) => {
  const { clientId, sessionId } = useContext(AuthContext);

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: `${getApiEndpoint(endpoint)}${route || ""}`,
      headers: {
        "Content-Type": "application/json",
        "Client-App-Id": clientId,
        "Client-User-Id": sessionId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, route]);

  return axiosInstance;
};

const useAuthenticatedApi: (
  endpoint: Endpoint,
  route?: string
) => AxiosInstance | null = (endpoint, route) => {
  const { token, clientId, sessionId } = useContext(AuthContext);

  const axiosInstance = useMemo(() => {
    return token
      ? axios.create({
          baseURL: `${getApiEndpoint(endpoint)}${route || ""}`,
          headers: {
            "Content-Type": "application/json",
            "Client-App-Id": clientId,
            "Client-User-Id": sessionId,
            Authorization: `Bearer ${token}`,
          },
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, endpoint, route, sessionId]);

  return axiosInstance;
};

const getCustomApi: (
  endpoint: Endpoint,
  token: string,
  clientId: string,
  sessionId: string
) => AxiosInstance | null = (endpoint, token, clientId, sessionId) => {
  return axios.create({
    baseURL: `${getApiEndpoint(endpoint)}`,
    headers: {
      "Content-Type": "application/json",
      "Client-App-Id": clientId,
      "Client-User-Id": sessionId,
      Authorization: `Bearer ${token}`,
    },
  });
};

export { useUnauthenticatedApi, useAuthenticatedApi, getCustomApi };
