import { createContext } from "react";

interface AuthContextInterface {
  token: string | null;
  isLoggedIn: boolean | null;
  sessionId: string;
  clientId: string;
  tokenExpiry: string | null;
  user: any;
  ssoDetails: SSODetails | null;
  logout: () => void;
  setTokenDetails: (token: string, expiry: string) => void;
  setAllowSSO: (allow: boolean) => void;
  setUser: (u: any) => void;
  source: "zostel" | "zoworld";
}

const AuthContext = createContext<AuthContextInterface>({
  token: null,
  isLoggedIn: null,
  tokenExpiry: null,
  sessionId: "",
  clientId: "",
  ssoDetails: null,
  user: null,
  source: "zostel",
  logout: () => {},
  setTokenDetails: (token: string, expiry: string) => {},
  setUser: (u: any) => {},
  setAllowSSO: (allow: boolean) => {},
});

export default AuthContext;
