import { createContext } from "react";
import { GeneralObject } from "../../definitions/general";

type ProfileContextInterface = {
  profile: GeneralObject;
  updateProfile: (updatedProfile: GeneralObject) => void;
};

const ProfileContext = createContext<ProfileContextInterface>({
  profile: {},
  updateProfile: (updatedProfile: GeneralObject) => {},
});

export default ProfileContext;
