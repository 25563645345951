import React from "react";
import ReactDOM from "react-dom";

import "./assets/styles/main.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/auth";
import { ProfileProvider } from "./contexts/profile";

if (
  process.env.NODE_ENV === "production" &&
  !localStorage.getItem("zozoConsole")
) {
  window.console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ProfileProvider>
          <App />
        </ProfileProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
