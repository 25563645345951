import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

interface MobileInputProps {
  className?: string;
  setter: React.Dispatch<any>;
  value: any;
  disabled?: boolean;
}

const MobileInput = React.forwardRef<HTMLDivElement, MobileInputProps>(
  ({ className, setter, value, disabled }, ref) => {
    return (
      <div className={`mobile-input ${className ? className : ""}`} ref={ref}>
        <PhoneInput
          country={"in"}
          value={value}
          onChange={setter}
          placeholder="+91 98765-43210"
          searchPlaceholder="Search for your country"
          enableSearch={true}
          inputClass="form-input"
          disabled={disabled}
        />
      </div>
    );
  }
);

export default MobileInput;
