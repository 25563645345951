export type AuthEndpoint =
  | "MAGIC_LOGIN"
  | "SSO_AUTHENTICATE"
  | "SET_PASSWORD"
  | "REQUEST_OTP"
  | "ACCOUNT_CHECK"
  | "ACTIVATE_ACCOUNT"
  | "LOGIN";

export const AUTH_ENDPOINTS: { [key: string]: string } = {
  MAGIC_LOGIN: "/login/magic/",
  SSO_AUTHENTICATE: "/sso/authenticate/",
  SET_PASSWORD: "/password/",
  REQUEST_OTP: "/request-otp/",
  ACCOUNT_CHECK: "/check/",
  ACTIVATE_ACCOUNT: "/activate/",
  LOGIN: "/login/",
};
