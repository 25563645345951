import { createHash } from "crypto";
import { GeneralObject } from "../../definitions/general";

import { AUTH_ENDPOINTS, AuthEndpoint } from "./collections/auth";
import { PROFILE_ENDPOINTS, ProfileEndpoint } from "./collections/profile";

export type Endpoint = AuthEndpoint | ProfileEndpoint;

export const getApiEndpoint: (endpoint: Endpoint) => string = (
  endpoint: Endpoint
) => {
  const authEndpoints = Object.keys(AUTH_ENDPOINTS);
  const profileEndpoints = Object.keys(PROFILE_ENDPOINTS);

  if (authEndpoints.indexOf(endpoint) !== -1) {
    return `${process.env.REACT_APP_API_AUTH}${AUTH_ENDPOINTS[endpoint]}`;
  }
  if (profileEndpoints.indexOf(endpoint) !== -1) {
    return `${process.env.REACT_APP_API_PROFILE}${PROFILE_ENDPOINTS[endpoint]}`;
  }
  return "";
};

export const createNewSession: () => string = () => {
  const sessionId = createHash("sha1")
    .update(
      JSON.stringify({
        timestamp: Date.now(),
        rand: Math.random() * 10000,
      })
    )
    .digest("hex")
    .substr(0, 10);
  localStorage.setItem("sessionId", sessionId);
  return sessionId;
};

export const redirectSSO = (
  callbackURL: string,
  response: GeneralObject,
  redirectUrl?: string
) => {
  const form: any = document.createElement("FORM");
  form.method = "POST";
  form.style = "display:none";
  form.action = callbackURL;
  form.innerHTML = `<input type="text" value="${
    response.user_token
  }" name="token" hidden /><input type="text" value="${
    response.token_expiry
  }" name="token_expiry" hidden /><input type="text" value='${JSON.stringify(
    response.user
  )}' name="user" hidden />`;
  if (redirectUrl != null && redirectUrl !== "") {
    form.innerHTML += `<input type="text" value="${redirectUrl}" name="redirect_url" hidden />`;
  }
  document.body.appendChild(form);
  form.submit();
};
