import { useEffect, useState } from "react";

const useResponseFlash: (
  timeout?: number
) => {
  response: string | boolean;
  setResponse: React.Dispatch<string | boolean>;
} = (timeout = 3000) => {
  const [response, setResponse] = useState<string | boolean>("");

  useEffect(() => {
    let mounted = true;
    if (response) {
      setTimeout(() => {
        if (mounted) {
          setResponse("");
        }
      }, timeout);
    }

    return () => {
      mounted = false;
    };
  }, [response, timeout]);

  return { response, setResponse };
};

export default useResponseFlash;
