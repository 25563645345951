import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";

interface SSOErrorProps {}

const SSOError: React.FC<SSOErrorProps> = () => {
  const { source } = useContext(AuthContext);
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <aside className="w-1/3 portrait:w-full portrait:absolute top-0 left-0 portrait:p-12 shadow-inner flex flex-col items-center">
      <header className="px-4 bg-white flex-col flex items-center w-full flex-shrink-0 h-content py-12 portrait:rounded-t-lg">
        <h1 className="font-bold text-5xl text-orange">
          {source === "zostel" ? "Zostel" : "Zo World"}
        </h1>
        <h2 className="font-bold text-3xl text-gray-700">Oops</h2>
      </header>
      <section className="flex flex-col items-center w-full bg-gray-100 portrait:rounded-b-lg py-12 px-4">
        <span className="text-gray-800 text-xl">Something went wrong!</span>
        <button
          className="bg-orange font-medium px-4 py-3 text-white rounded-lg shadow-md mt-8"
          onClick={goBack}
        >
          Go Back
        </button>
      </section>
    </aside>
  );
};

export default SSOError;
