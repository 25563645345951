import React, { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";
import { ProfileContext } from "../../../contexts/profile";
import Avatar from "../../ui/Avatar";

interface UserSectionProps {
  reset: () => void;
}

const UserSection: React.FC<UserSectionProps> = ({ reset }) => {
  const { logout, source } = useContext(AuthContext);
  const { profile } = useContext(ProfileContext);
  // const authApi = useApi("auth", true);

  // const goToProfile = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const data = {
  //     client_app_id: "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
  //     client_user_id: createNewSession(),
  //   };
  //   authApi
  //     .post("/auth/api/v1/sso/authenticate/", JSON.stringify(data))
  //     .then((response) => {
  //       try {
  //         setLoading(false);
  //         redirectSSO(
  //           `https://${process.env.REACT_APP_ROOT_DOMAIN}/auth/callback`,
  //           response.data,
  //           data.client_user_id
  //         );
  //       } catch (error) {
  //         console.log(error);
  //         setLoading(false);
  //         window.location = `https://${process.env.REACT_APP_ROOT_DOMAIN}`;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //       window.location = `https://${process.env.REACT_APP_ROOT_DOMAIN}`;
  //     });
  // };

  // const goToSettings = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const data = {
  //     client_app_id: "Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU",
  //     client_user_id: createNewSession(),
  //   };
  //   authApi
  //     .post("/auth/api/v1/sso/authenticate/", JSON.stringify(data))
  //     .then((response) => {
  //       try {
  //         setLoading(false);
  //         redirectSSO(
  //           `https://me.${process.env.REACT_APP_ROOT_DOMAIN}/#settings`,
  //           response.data,
  //           data.client_user_id
  //         );
  //       } catch (error) {
  //         console.log(error);
  //         setLoading(false);
  //         window.location = `https://me.${process.env.REACT_APP_ROOT_DOMAIN}/#settings`;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //       window.location = `https://me.${process.env.REACT_APP_ROOT_DOMAIN}/#settings`;
  //     });
  // };

  return (
    <aside className="w-1/3 portrait:w-full portrait:absolute top-0 left-0 portrait:p-12 shadow-inner flex flex-col items-center">
      <header className="px-4 bg-white flex-col flex items-center w-full flex-shrink-0 h-content py-12 portrait:rounded-t-lg">
        <h1 className="font-bold text-5xl text-orange">
          {source === "zostel" ? "Zostel" : "Zo World"}
        </h1>
        <h2 className="font-bold text-3xl text-gray-700">User Section</h2>
      </header>
      <section className="flex flex-col items-center w-full bg-gray-100 portrait:rounded-b-lg pb-12">
        {profile && (
          <>
            <Avatar
              className="w-24 h-24 mt-8 bg-white border rounded-full"
              svg={profile.avatar_url}
            />
            <div className="text-gray-800 font-medium text-lg mt-2">
              {profile.nickname || profile.first_name || "Zosteler"}
            </div>
          </>
        )}

        {/* <button
          className="bg-orange font-medium px-4 py-3 text-white rounded-lg shadow-md my-12"
          onClick={goToProfile}
        >
          {loading ? "redirecting" : "Open Zo World"}
        </button> */}
        <div className="border-2 bg-white rounded-lg flex flex-col w-72 mt-8">
          <button
            className="rounded-t-lg border-b p-2 text-center focus:outline-none hover:bg-orange hover:text-white"
            onClick={reset}
          >
            Reset Password
          </button>
          {/* <button
            className="border-b p-2 text-center focus:outline-none hover:bg-orange hover:text-white"
            onClick={goToSettings}
          >
            Account Settings
          </button> */}
          <button
            className="rounded-b-lg p-2 text-center focus:outline-none hover:bg-orange hover:text-white"
            onClick={logout}
          >
            Log out
          </button>
        </div>
      </section>
    </aside>
  );
};

export default UserSection;
