import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getCustomApi, useUnauthenticatedApi } from "../../hooks/useApi";
import { createNewSession, redirectSSO } from "../../utils/api";
import AuthContext from "./AuthContext";

interface AuthProviderProps {}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const magicLinkApi = useUnauthenticatedApi("MAGIC_LOGIN");
  const history = useHistory();
  const location = useLocation();

  const [token, setToken] = useState<string | null>(null);
  const [tokenExpiry, setTokenExpiry] = useState<string | null>(null);
  const clientId = useRef<string>(
    process.env.REACT_APP_CLIENT_ID || ""
  ).current;
  const [isLoggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [sessionId, setSessionId] = useState<string>(createNewSession());
  const [user, setUser] = useState<any>();

  const [ssoDetails, setSSODetails] = useState<SSODetails | null>(null);
  const [source, setSource] = useState<"zostel" | "zoworld">("zostel");
  const [allowSSO, setAllowSSO] = useState<boolean>(false);

  useEffect(() => {
    // Initial Checkup
    const localToken = localStorage.getItem("token");
    const localTokenExpiry = localStorage.getItem("token_expiry");
    const localUnparsedUser = localStorage.getItem("user");
    const localUser = localUnparsedUser ? JSON.parse(localUnparsedUser) : null;
    const localClientAppId = localStorage.getItem("client_app_id");
    const localClientSessionId = localStorage.getItem("client_session_id");
    const localCallbackUrl = localStorage.getItem("callback_url");
    const localRedirectUrl = localStorage.getItem("redirect_url");

    if (localToken && localUser && localTokenExpiry) {
      // If already logged in
      if (
        new Date(JSON.parse(localTokenExpiry)).getTime() > new Date().getTime()
      ) {
        setToken(localToken);
        setUser(localUser);
        setSessionId(localUser.user_id);
        setLoggedIn(true);
      } else {
        setSessionId("");
        setToken(null);
        setUser(null);
        setLoggedIn(false);
      }
    } else {
      setSessionId("");
      setToken(null);
      setUser(null);
      setLoggedIn(false);
    }
    if (localClientSessionId && localCallbackUrl && localClientAppId) {
      if (
        localCallbackUrl.includes("zostel") ||
        localCallbackUrl.includes("/app/auth/callback")
      ) {
        setSource("zostel");
      } else {
        setSource("zoworld");
      }

      // coming for SSO
      setSSODetails({
        app_id: localClientAppId,
        callback_url: localCallbackUrl,
        session_id: localClientSessionId,
        redirect_url: localRedirectUrl,
      });
      // setRedirectUrl(localCallbackUrl);
    }
    // Clearing cache
    localStorage.removeItem("client_app_id");
    localStorage.removeItem("client_session_id");
    localStorage.removeItem("callback_url");
    localStorage.removeItem("redirect_url");
  }, []);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      localStorage.setItem("user", JSON.stringify(user));
      setSessionId(user.user_id);
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    }
  }, [token]);

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem("sessionId", sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (token && user && sessionId) {
      setLoggedIn(true);
    }
  }, [token, user, sessionId]);

  useEffect(() => {
    if (location.pathname.startsWith("/l/") && magicLinkApi) {
      const token = location.pathname.substr(3);
      magicLinkApi
        .post("", JSON.stringify({ token }))
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setTokenDetails(
              response.data.user_token,
              response.data.token_expiry
            );
            setUser(response.data.user);
            setSSODetails({
              app_id: process.env.REACT_APP_ZO_WORLD_CLIENT_ID || "",
              session_id: createNewSession(),
            });
          } else {
            history.push("/sso-error");
          }
        })
        .catch((e) => {
          console.log(e);
          history.push("/sso-error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (ssoDetails && isLoggedIn && token) {
      if (allowSSO) {
        const api = getCustomApi(
          "SSO_AUTHENTICATE",
          token,
          clientId,
          sessionId
        );
        if (api) {
          const data = {
            client_app_id: ssoDetails.app_id,
            client_user_id: ssoDetails.session_id,
          };
          api
            .post("", JSON.stringify(data))
            .then((response) => {
              try {
                if (user.signup) {
                  if (
                    ssoDetails.callback_url &&
                    ssoDetails.callback_url.includes("/app/auth/callback")
                  ) {
                    redirectSSO(
                      ssoDetails.callback_url,
                      response.data,
                      ssoDetails.redirect_url || undefined
                    );
                  } else if (
                    ssoDetails.callback_url &&
                    ssoDetails.callback_url.includes("zostel.com")
                  ) {
                    setUser((u: any) => {
                      const _u = { ...u };
                      delete _u["signup"];
                      return _u;
                    });
                    redirectSSO(
                      ssoDetails.callback_url,
                      response.data,
                      ssoDetails.redirect_url || undefined
                    );
                  } else {
                    redirectSSO(
                      `https://${process.env.REACT_APP_ROOT_DOMAIN}/auth/callback`,
                      response.data
                    );
                  }
                } else {
                  if (!ssoDetails.callback_url) {
                    redirectSSO(
                      `https://${process.env.REACT_APP_ROOT_DOMAIN}/auth/callback`,
                      response.data
                    );
                  } else {
                    redirectSSO(
                      ssoDetails.callback_url,
                      response.data,
                      ssoDetails.redirect_url || undefined
                    );
                  }
                }
              } catch (error) {
                console.log(error);
                history.push("/sso-error");
              }
            })
            .catch((e) => {
              console.log(e);
              if (e.response.status === 401) {
                history.push("/not-allowed");
              } else {
                history.push("/sso-error");
              }
            });
        }
      } else {
        history.push("/authorise");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoDetails, isLoggedIn, allowSSO]);

  const setTokenDetails = (token: string, expiry: string) => {
    setToken(token);
    localStorage.setItem("token_expiry", JSON.stringify(expiry));
  };

  const logout = () => {
    setLoggedIn(false);
    setUser(null);
    setToken(null);
    setTokenExpiry(null);
    setAllowSSO(true);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        tokenExpiry,
        clientId,
        token,
        isLoggedIn,
        sessionId,
        user,
        logout,
        ssoDetails,
        source,
        setUser,
        setTokenDetails,
        setAllowSSO,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
