import { GeneralObject } from "../definitions/general";

const APPS: GeneralObject = {
  FrcIH2m03QxVgFD037u8oaQczaAImvAN506cUQb4: "Zostel",
  "5Njb5awMk0dbC7VNnY7Z35tw2yEE1HtA92r9YA1t": "Zostel Admin",
  "6BxZKWcDXiERCfKYIHkBinMvyHyruhB4Xue8ZhtJ": "Zobu Chat",
  IK8aD5vHBePcrdOlT87IROuZwvUsa6IT0aGKGUi5: "Community App",
  Ne0HsSgWroMJkV9JQBpWd7ZdGIqARRnKeSYhRdVU: "Zo World",
};

export default APPS;
