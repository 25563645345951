import React, { useRef, useState } from "react";

interface OTPInputProps {
  onChange: (otp: string) => void;
}

const OTPInput = React.forwardRef<HTMLInputElement, OTPInputProps>(
  ({ onChange }, ref) => {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const input2Ref = useRef<HTMLInputElement>(null);
    const input3Ref = useRef<HTMLInputElement>(null);
    const input4Ref = useRef<HTMLInputElement>(null);
    const input5Ref = useRef<HTMLInputElement>(null);
    const input6Ref = useRef<HTMLInputElement>(null);

    const handleChange = (index: number, e: any) => {
      const { value, maxLength } = e.target;
      const __otp = value.slice(0, maxLength);
      setOtp((_otp) => {
        const o = [..._otp];
        o[index] = __otp;
        onChange(o.join(""));
        return o;
      });
      switch (index) {
        case 0:
          if (input2Ref.current) {
            input2Ref.current.focus();
          }
          break;
        case 1:
          if (input3Ref.current) {
            input3Ref.current.focus();
          }
          break;
        case 2:
          if (input4Ref.current) {
            input4Ref.current.focus();
          }
          break;
        case 3:
          if (input5Ref.current) {
            input5Ref.current.focus();
          }
          break;
        case 4:
          if (input6Ref.current) {
            input6Ref.current.focus();
          }
          break;
        default:
          break;
      }
    };

    return (
      <div className="w-72 flex items-center">
        <input
          type="number"
          value={otp[0]}
          ref={ref}
          maxLength={1}
          className="form-input w-1/6 appearance-none mr-2 border rounded-lg text-center"
          onChange={handleChange.bind(null, 0)}
        />
        <input
          type="number"
          value={otp[1]}
          maxLength={1}
          className="form-input w-1/6 appearance-none mr-2 border rounded-lg text-center"
          onChange={handleChange.bind(null, 1)}
          ref={input2Ref}
        />
        <input
          type="number"
          value={otp[2]}
          maxLength={1}
          className="form-input w-1/6 appearance-none mr-2 border rounded-lg text-center"
          onChange={handleChange.bind(null, 2)}
          ref={input3Ref}
        />
        <input
          type="number"
          value={otp[3]}
          maxLength={1}
          className="form-input w-1/6 appearance-none mr-2 border rounded-lg text-center"
          onChange={handleChange.bind(null, 3)}
          ref={input4Ref}
        />
        <input
          type="number"
          value={otp[4]}
          maxLength={1}
          className="form-input w-1/6 appearance-none mr-2 border rounded-lg text-center"
          onChange={handleChange.bind(null, 4)}
          ref={input5Ref}
        />
        <input
          type="number"
          value={otp[5]}
          maxLength={1}
          className="form-input w-1/6 appearance-none border rounded-lg text-center"
          onChange={handleChange.bind(null, 5)}
          ref={input6Ref}
        />
      </div>
    );
  }
);

export default OTPInput;
