import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import { AuthContext } from "../../../contexts/auth";

interface ProtectedRouteProps {
  path: string;
  component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ path, component }) => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Route path={path} exact>
      {isLoggedIn != null ? (
        isLoggedIn ? (
          React.createElement(component, {})
        ) : (
          <Redirect to="/" />
        )
      ) : null}
    </Route>
  );
};

export default ProtectedRoute;
